@import "../../../styles/fonts";
@import "../../../styles/colors";

.formItem{
  .ant-form-item-required{
    font-style: normal;
    font-weight: 400;
    font-family: 'Poppins' sans-serif !important;
    color: #463649 !important;
  }

  .ant-picker:hover, .ant-picker-focused {
    border-color: #CC8DFF;
    outline: none !important;
		box-shadow: none !important;
    border-right-width: 1px;
}

  .datePicker{
    width: 100%;
    height: 48px;
    border: 1px solid #CC8DFF;
    outline: none !important;
    border-radius: 10px;
    padding: 0 5px 0 20px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #463649 !important;

    &:focus {
			box-shadow: var(#CC8DFF) !important;
			border-color: var(#CC8DFF) !important;
			outline-color: var(#CC8DFF) !important;
		}

		&:hover {
			box-shadow: var(#CC8DFF) !important;
			border-color: var(#CC8DFF) !important;
			outline-color: var(#CC8DFF) !important;
		}

		&:active {
			box-shadow: var(#CC8DFF) !important;
			border-color: var(#CC8DFF) !important;
			outline-color: var(#CC8DFF) !important;
		}

    .ant-picker-suffix{
      margin-right: 10px;
    }

    .ant-picker-input{
      input{  
        &::placeholder{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #463649;
        }
      }

      .anticon{
        color: #463649;
      }
    }

    
  }
}