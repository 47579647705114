@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.sharing-game {
  background-color: $color-7;
  padding: 30px 25px;
  border-radius: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: $recoleta;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    margin: 0;
    margin-bottom: 25px;
    left: -10px;

    img {
      margin-right: 10px;
    }
  }

  .button {
    width: 100%;
    margin-bottom: 12px;

    &.secondary {
      width: calc((100% / 2) - 5px);
    }
  }

  .body-modal {
    p {
      margin: 0;
    }

    .title {
      font-family: Recoleta Alt;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      text-align: center;
      margin-bottom: 20px;
    }

    .description {
      max-width: 374px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 19px;
      text-align: center;
    }
  }
}
