.input-field {
  font-size: 13px;
  line-height: 19.5px;

  label {
    letter-spacing: -0.7px;
    font-family: 'Poppins' sans-serif !important;
  }
  .error{
    color:  #E03D32;
  }
  .border-error {
    position: relative;
    display: flex;
    border: 1px solid #E03D32;
    border-radius: 10px;
    overflow: hidden;
    height: 48px;
    margin-top: 10px;

    img {
      position: absolute;
      top: 13px;
      left: 15px;
      width: 22px;
      height: 22px;
      object-fit: contain;

    }

    input {
      width: 100%;
      height: 100%;
      appearance: none;
      border: none;
      outline: none;
      padding: 0 5px 0 40px;
      font-weight: 500;
      background: #FFDDE1;

      &:focus {
        border: none;
        outline: none;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
      .error-input-field {
        color:  #E03D32;
      }
    }
  }
    .border {
      position: relative;
      display: flex;
      border: 1px solid #CC8DFF;
      border-radius: 10px;
      overflow: hidden;
      height: 48px;
      margin-top: 10px;


      img {
        position: absolute;
        top: 13px;
        left: 15px;
        width: 22px;
        height: 22px;
        object-fit: contain;
      }

      input {
        width: 100%;
        height: 100%;
        appearance: none;
        border: none;
        outline: none;
        padding: 0 5px 0 40px;
        font-weight: 500;

        &:focus {
          border: none;
          outline: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;
        }
      }
    }
  .bold{
    font-weight: bold !important;
  }

  @media (max-width: 450px) { 
    .removePadding{
      input{
        padding: 0 5px 0 20px;
      }
    }

    // .border{
    //   input{
    //     padding: 0 5px 0 40px;
    //   }
    //   img{
    //     display: block;
    //     background-color: red;
    //     margin-right: 20px !important;
    //   }
    // }
  }
}