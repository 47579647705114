@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.show-raised {
  background-color: $color-7;
  padding: 30px 25px;
  border-radius: 30px;
  width: 100%;

  p {
    margin: 0;
  }

  .banner{
    border-radius: 12px;
    border: none;
    .ant-alert-message{
      font-family: 'Poppins' !important;
      color: #ffffff !important;
      font-size: 14px;
    }
  }
  & > .title {
    font-size: 16px;
    margin-top: 22px;
    margin-bottom: 10px;
    padding: 0 15px;
    font-weight: 700;
    display: flex;
    align-items: center;

    .info {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 10px;

      & > img {
        width: 23px;
        height: 23px;
      }

      .tooltip-raised {
        visibility: hidden;
        opacity: 0;
        display: flex;
        padding: 12px 14px;
        position: absolute;
        background: #ffffff;
        border: 1.74171px solid #000000;
        box-shadow: 0px 6.96685px 6.96685px rgba(0, 0, 0, 0.13);
        border-radius: 8.70857px;
        left: -350%;
        bottom: 140%;
        min-width: 189px;
        transition: all 0.3s;

        img {
          width: 40px;
          height: auto;
          margin-right: 10px;
        }

        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          letter-spacing: 0.09em;
          text-transform: uppercase;
          color: $color-4;
        }

        .price {
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          display: flex;
          align-items: center;
          font-family: $recoleta;
        }
      }

      &:hover {
        .tooltip-raised {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .socialIconsBox{
    margin-top: 30px;
    padding-left: 10px;
  }

  .total {
    font-weight: 700;
    font-size: 40px;
    font-family: $recoleta;
    margin-bottom: 25px;
    padding: 0 15px;
  }

  ul.list {
    max-height: 140px;
    overflow: auto;
    padding: 0 15px;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-8;
      border-radius: 30px;
    }

    li {
      font-size: 16px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      // align-items: center;

      .name {
        margin-left: 15px;

        .genderImage{
          width: 15px;
          margin-bottom: 5px;
        }
      }

      .locked{
        color: $color-6;
      }
    }
  }
}
