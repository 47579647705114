@import "../../../styles/fonts";
@import "../../../styles/colors";

.modal-small {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  z-index: 20;

  .background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.301);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .body-modal {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 10%;
    background: $color-1;
    box-shadow: 0px 25px 50px 20px rgba(0, 0, 0, 0.01);
    border-radius: 34px;
    padding: 30px 60px;
    width: calc(100% - 30px);
    max-width: 726px;
    min-height: 300px;
    box-sizing: border-box;
    transition: top 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-btn {
      cursor: pointer;
      width: 31px;
      height: 31px;
      position: absolute;
      right: 20px;
      top: 20px;

      &:hover {
        opacity: 90%;
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;

    .body-modal {
      top: 20%;
    }
  }
}
