@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.VoteButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $recoleta;

  button {
    border: none;
    appearance: none;
    font-family: $recoleta;
    cursor: pointer;
  }

  .minus,
  .plus {
    width: 61px;
    height: 59px;
    border-radius: 10px;
    background: $fog;
    color: $color-10;
    box-shadow: 0px 0px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
    font-size: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .plus {
    background: $color-10;
    color: $color-1;
  }

  .vote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 150px;
    border-radius: 20px;
    background: $color-10;
    color: $color-1;
    box-shadow: 0px 0px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
    margin: 0 29px;
    text-align: center;
    font-weight: bold;

    span {
      &:first-child {
        line-height: 75px;
        margin-top: -5px;
      }

      font-size: 70px;
    }

    label {
      font-size: 30px;
      margin-top: -10px;
    }

    small {
      font-size: 20px;
      margin-top: -5px;
    }
  }

  &.girl {
    .minus {
      background: $color-4;
      color: $color-1;

      &:disabled {
        background: $color-12;
        color: $color-4;
      }
    }

    .plus,
    .vote {
      background: $color-4;
      color: $color-1;
    }
  }

  &.boy {
    .minus {
      background: $color-10;
      color: $color-1;

      &:disabled {
        background: $fog;
        color: $color-10;
      }
    }

    .plus,
    .vote {
      background: $color-10;
      color: $color-1;
    }
  }
}


@media (max-width: 450px) {
  .minus,
  .plus {
    width: 40px !important;
    height: 40px !important;
  }

  .vote{
    width: 120px !important;
    height: 120px !important;

    span{
      font-size: 40px !important;
      line-height: 25px !important;
      margin-top: 10% !important;
      margin-bottom: 15% !important;
    }

    label{
      font-size: 25px !important;
    }

    small{
      letter-spacing: 1.4px !important;
    }
  }
}