@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.game-host {
  background-color: #fff;
  padding: 10px;
  width: 688px;
  max-width: 100%;

  .title {
    font-size: 50px;
    font-family: $recoleta;
    line-height: 54.5px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 16px;
  }

  .slick-slider {
    .customArrowLeft,
    .customArrowRight {
      position: absolute;
      z-index: 10;
      bottom: 40px;
      cursor: pointer;
      padding: 2%;
      background-color: #5263FF;
        border-radius: 10%;
    }

    .customArrowLeft {
      right: 10%;
    }

    .customArrowRight {
      right: 5%;
    }
  }

  @media (max-width: 450px) {
    .slick-slider {
      .customArrowLeft,
      .customArrowRight {
        padding: .1%;

        background-color: #5263FF !important;
        border-radius: 10%;
      }
    }
  }



  .main-image {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .placeHolderImage{
    width: 100% !important;
    height: 376px;
    margin-top: 16px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .sub-text {
    font-size: 30px;
    font-weight: 700;
    font-family: $recoleta;
    line-height: 32.7px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 8px;
  }

  .details {
    margin-bottom: 20px;

    .city-text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .description {
      font-size: 13px;
      line-height: 19.5px;
      margin-top: 10px;
    }
  }
  .babyUpdates {
    margin-top: 40px;
    .sub-text {
      padding-bottom: 17px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 8px;
    }

    .updatesDetailsBox{
      height: 150px;
      overflow-y: auto;
      padding-right: 10px;
      .updateDetails{
        .initialIcon{
          width: 13px !important;
          height: 13px !important;
          margin-bottom: 9px;
        }
        font-size: 13px;
        line-height: 19.5px;
        margin-top: 10px;
        .deleteIcon{
          color: rgba(199, 0, 0, 0.8);
          cursor: pointer;
          float: right;
        }
      }
    }

    
    img {
      width: 131px;
      margin-top: 8px;
    }
  }

  .body-modal {
    p {
      margin: 0;
    }

    .title {
      font-family: $recoleta;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      text-align: center;
      margin-bottom: 20px;
      line-height: 54.5px;
      max-width: 453px;
    }

    .description {
      max-width: 374px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
      text-align: center;
    }

    button {
      display: flex;
      max-width: 307px;
      margin: 0 auto 13px;

      img {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 520px) {
    .title {
      font-size: 30px;
      line-height: 34.5px;
    }

    .sub-text {
      font-size: 25px;
    }
  }
}


@media (max-width: 450px) { 
  .title{
    font-size: 28px !important;
    letter-spacing: 1.1px;
  }

  .main-image{
    border-radius: 34px;
  }

  .placeHolderImage{
    border-radius: 34px;
    width: 343px;
    height: 206px;
  }

  .details{
    .sub-text{
      font-size: 20px !important;
    }

    .description{
      margin-top: 9% !important;
      letter-spacing: 1.1px;
      font-size: 14px !important;
      line-height: 25px !important;
      text-align: justify !important;
    }
  }
}
