@import "../../../../styles/fonts";
@import "../../../../styles/colors";

.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .frame {
    display: flex;
    width: 1086px;
    max-width: 100%;
    height: 799px;
    background: #fff;
    border-radius: 34px;
    overflow: hidden;

    .couple-img {
        height: 100%;
        max-width: 100%;
        width: 100%;
        object-fit: cover;
    }

    .information {
      width: 49.35%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .login {
      width: 50.65%;
      padding: 50px 88px 111px 88px;
      text-align: left;
      font-size: 13px;
      background: linear-gradient(0deg, #f7f7ff, #f7f7ff);
      border-radius: 0 0 34px 34px;


      h1 {
        font-family: "Recoleta", sans-serif;
        margin-top: 35px;
        margin-bottom: 20px;
      }

      .input-field {
        margin-bottom: 21px;
      }

      .button-default {
        margin-top: 22px;
        margin-bottom: 13px;
      }
      .error-msg{
        color: #FF4D4F;
        font-size: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
      }

      .primaryDisabled{
        color: #E9C6FC;
        border-color: #E9C6FC;
      }

      a {
        font-weight: 500;
        color: #463649;
      }

      p {
        margin-top: 54px;

        &.details {
          margin-top: 23px;
          margin-bottom: 21px;
        }

        a {
          font-weight: 700;
          color: #cc8dff;
        }
      }

      @media (max-width: 900px) {  
        h1 {
          text-align: center;
        }

        .logo{
            margin-left: 22%;
        }
      }

      @media (max-width: 450px) { 
        
        .logo{
            margin-left: 0px;
            width: 100px;
        }
      }

      @media (max-width: 620px) {
        padding: 67px 10% 20px 10%;
      }
      
    }
  }

  

  @media (max-width: 920px) {
    height: auto;
    
    .frame {
      display: flex;
      flex-wrap: wrap;
      height: auto;

      .information,
      .login {
        width: 100%;
      }

      .login {
        form {
          margin: auto;
        }
      }

    }
  }
}

@media (max-width: 920px) {
  .bg-register {
    overflow: auto !important /*To overwrite styles inline*/;
    height: auto;
  }
}

@media (max-width: 450px) { 
  .login-screen {
    .frame{
      height: 100%;
      .login{
        height: 100vh !important;
        border-radius: 0 !important;
        padding-top: 30px;
        h1{
          margin-top: 15%;
          margin-bottom: 10%;
          text-align: left;

        }

        .button-default {
          margin-bottom: 27px !important;
        }

        .bottomDiv{
          margin-top: -20px;
          p{
            margin-top: 25px;
          }
        }
      }
    }
  }
  
}
