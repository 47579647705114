@import "../../styles/fonts";

.pieChart {
  width: 145px;
  height: 145px;

  text {
    font-family: $recoleta;
    font-weight: 600;
    text-align: center;

    &:first-child {
      font-size: 20.44px;
      line-height: 16.61px;
    }

    &:last-child {
      font-size: 15.33px;
      line-height: 16.61px;
    }
  }
}
