.text-area-field {
  font-size: 13px;
  line-height: 19.5px;

  label {
    display: block;
    letter-spacing: -0.7px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 14px;

    span {
      display: block;
      font-weight: 700;
      margin-bottom: 14px;
    }
  }

  textarea {
    width: 100%;
    height: 143px;
    appearance: none;
    border: none;
    outline: none;
    padding: 20px;
    font-size: 13px;
    border-radius: 10px;
    border: 1px solid #cc8dff;
    line-height: 19px;
    resize: none;

    &:focus {
      outline: none;
    }
  }
}
