@import "../../styles/colors";

footer {
  padding: 29px 10px;
  text-align: center;
  font-size: 13px;
  line-height: 19.5px;
  color: $color-5;
  transition: color 0.3s;

  a {
    text-decoration: none;
    color: $color-5;

    &:hover {
      color: $color-2;
    }
  }
}

@media (max-width: 450px) { 
  footer{
    background-color: #FFF;
  }
}
