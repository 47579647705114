.button-default {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 353px;
  max-width: 100%;
  padding: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  appearance: none;
  background: #fff;
  border: 1px solid #cc8dff;
  color: #cc8dff;
  border-radius: 10px;
  transition: all 0.3s;
  &:disabled {
    cursor: not-allowed;
  }
  &.primary {
    background: #cc8dff;
    color: #fff;
  }

  &.primaryDisabled{
    background-color: #E9C6FC;
  }

  &.secondary {
    border-color: #463649;
    background: #463649;
    color: #fff;
  }

  &.danger {
    border-color: #e03d32;
    background: #e03d32;
    color: #fff;
  }

  &.back::before {
    content: "";
    display: inline-block;
    margin-right: 20px;
    background: url("../../../public/assets/arrow-right.png");
    width: 10px;
    height: 12px;
    transform: rotate(180deg);
  }

  &.next::after {
    content: "";
    display: inline-block;
    margin-left: 20px;
    background: url("../../../public/assets/arrow-right.png");
    width: 10px;
    height: 12px;
  }

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    filter: brightness(0.9);
  }
}
