@import "../../styles/fonts";
@import "../../styles/colors";

.SidebarCompleted {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F1EFF1;
  width: 405px;
  max-width: 100%;
  min-height: 880px;
  border-radius: 30px;
  padding: 40px;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background: url('../../../public/assets/top-hearts.svg') no-repeat center center;
    width: 100%;
    height: 103px;
    z-index: 0;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    background: url('../../../public/assets/bottom-bear2.svg') no-repeat center right;
    width: 100%;
    height: 229px;
    z-index: 0;
  }

  h2 {
    position: relative;
    font-size: 40px;
    line-height: 43.6px;
    margin: 0 auto 15px;
    z-index: 1;
  }

  .white-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 26px;
    min-height: 87px;
    max-width: 100%;
    background: #fff;
    box-shadow: 0px 0px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
    border-radius: 20px;
    z-index: 1;

    &.one {
      font-size: 35px;
      line-height: 38.15px;
      color: $color_10;
      font-family: $recoleta;
      padding: 22px 39px;
      margin-bottom: 25px;

      img {
        margin-right: 19px;
      }
    }

    &.two {
      width: 295px;
      font-size: 16px;
      margin-bottom: 25px;
    }

    &.three {
      flex-basis: 174px;
      flex-direction: column;
      align-items: flex-start;

      label {
        font-size: 16px;
        font-weight: 700;
      }

      strong {
        font-size: 40px;
        font-family: $recoleta;
        font-weight: 700;
      }

      span {
        font-size: 13px;
      }
    }

    &.four {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
      }

      label {
        font-size: 13px;
      }

      strong {
        font-size: 30px;
        font-family: $recoleta;
      }
    }
  }

  .pie {
    width: 145px;
    height: 145px;
    font-weight: 600;
  }

  .col {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 19px;
  }
}