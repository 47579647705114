$color-1: #fff;
$color-2: #000;
$color-3: #e5e5e5;
$color-4: #cc8dff;
$color-5: #463649;
$color-6: #db0606;
$color-7: #f1eff1;
$color-8: #c4c4c4;
$color-9: #dbdbdb;
$color-10: #5263ff;
$fog: #d2d6fd;
$color-12: #f5e9ff;