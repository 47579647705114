@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.gameCompleted {
  display: flex;
  flex-direction: column;
  width: 405px;
  max-width: 100%;

  .information-sidebar {
    text-align: center;
    position: relative;
    overflow: hidden;
    background: #f1eff1;
    border-radius: 30px;
    padding: 26px 0;
    margin-bottom: 24px;

    p {
      margin: 0;
    }

    .decoration {
      width: 113.85px;
      height: 143.14px;
      position: absolute;
      transform: matrix(0.93, -0.4, 0.36, 0.92, 0, 0);
      bottom: -40px;
      right: -30px;
      z-index: 0;
    }

    & > .title {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      font-family: $recoleta;
      margin-bottom: 20px;
      padding: 0 43px;
    }

    .box {
      position: relative;
      display: flex;
      margin-bottom: 40px;
      padding: 0 50px;

      .details{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 305px !important;
        height: 87px;
        background: $color-1;
        box-shadow: 0px 0px 24.3243px #ffffff,
          8.10811px 8.10811px 24.3243px rgba(174, 174, 192, 0.4);
        border-radius: 16.2162px;
        padding: 15px;
        max-width: 305px;
        min-height: 87px;
        flex: 1;
      
        img {
          width: 43px;
          margin-right: 10px;
        }
      
        span {
          font-family: $recoleta;
          font-style: normal;
          font-weight: 700;
          font-size: 35px;
        }

        .winner{
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #463649;
        }
      }
    }

    .data {
      position: relative;
      border-bottom: 1px solid $color-9;
      margin-bottom: 10px;

      .header {
        padding: 0 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        font-family: $recoleta;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid $color-9;
      }

      ul.list {
        padding: 0 43px 0;
        margin: 15px 0;
        max-height: 175px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 3px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-8;
          border-radius: 30px;
        }

        li {
          font-size: 16px;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            margin-left: 15px;
          }
        }
      }
    }
    .percentaje-details {
      flex-direction: column;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 43px 0;
      z-index: 1;
      margin-bottom: 40px;

      .chart {
        width: 145px;
        height: 145px;
        margin-right: 5%;
        margin-bottom: 5%;
      }

      .details {
        width: 154px;
        height: auto !important;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
        border-radius: 30px;
        text-align: left;
        padding: 20px 25px;
        .title {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #463649;
        }

        .subtitle {
          font-family: $recoleta;
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          color: #463649;
        }

        .description {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #463649;
        }
      }
    }

    @media (max-width: 820px) { 
      .percentaje-details{
        flex-direction: column;

        .details {
          margin-top: 10%;
        }
      }

      .box{
        .details{
          img{
            width: 33px
          }
          span{
            font-size: 25px;
          }
        }
      }
    }

    @media (max-width: 450px) { 
      .percentaje-details{
        flex-direction: column;

        .details {
          height: auto !important;

          .subtitle{
            margin-bottom: 0 !important;
            padding: 0 !important;
            line-height: 40px;
          }
          .description{
            line-height: 20px !important;
          }
        }
        .chart {
          margin-bottom: 5%;
        }
      }
    }
    .statsBox {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      .details{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        width: 100% !important;
        min-height: 87px;
        background: $color-1;
        box-shadow: 0px 0px 24.3243px #ffffff,
          8.10811px 8.10811px 24.3243px rgba(174, 174, 192, 0.4);
        border-radius: 16.2162px;
        padding: 15px;
        max-width: 305px;
        min-height: 87px;
        flex: 1;
      
        div{
          text-align: left;
          width: 130px;
          padding-left: 20px;
          margin-right: 10px;
          margin-top: 5px;
          .head{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #463649;
          }
  
          .desc{
            font-family: $recoleta;
            font-weight: 700;
            font-size: 30px;
            color: #463649;
          }
        }
        
      }
    }

    @media (max-width: 820px) {
      .statsBox {
          padding: 5%;
      }
  }

  }

  .sharing-game {
    margin-bottom: auto;
  }
}