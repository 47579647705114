@import "../../../styles/fonts";
@import "../../../styles/colors";

.present-url {
  display: flex;
  align-items: center;
  padding: 0 18px;


  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    margin: 0;
    margin-right: 21px;
    flex: 1;
  }

  .button-default {
    max-width: 164px;
    display: flex;

    .icon-btn {
      display: block;
      width: 20px;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 620px) {
    flex-wrap: wrap;
  }
}


@media (max-width: 450px) { 
  .present-url {
    flex-direction: column;
    align-items:flex-start;

    .button-default{
      margin-top: 8%;
    }
  }
}