@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.stepsContainer{

    .step{
        font-family: $recoleta;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: #463649;
        cursor: pointer;
        .dot {
            width: 10px;
            height: 10px;
            background: #CC8DFF;
            display: inline-block;
            border-radius: 50%;
            margin-left: 2%;
        }
    }

    @media (max-width: 450px) {
        .step{
            margin-right: 10%;
        }
    }
}