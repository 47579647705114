.Completed {
  .frame {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    box-shadow: 0px 25px 50px 20px rgba(0, 0, 0, 0.01);
    border-radius: 34px;
    min-height: 824px;
    max-width: 1241px;
    margin: 35px auto;
    padding: 65px 58px;

    .game-host {
      flex: 1 1 53%;
      width: 53%;
      padding-right: 46px;
    }

    .SidebarCompleted {
      flex-basis: 36%;
      width: 36%;
    }
  }

  @media (max-width: 1024px) {
    .frame {
      padding: 40px 20px;
    }
  }

  @media (max-width: 768px) {
    .frame {
      .game-host,
      .SidebarCompleted {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
}