@import "../../../styles/fonts";
@import "../../../styles/colors";

.stripeFormContainer{
    font-size: 13px;
    line-height: 19.5px;

    .customLabel {
        letter-spacing: -0.7px;
        display: block;
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .submitBtn{
        margin-top: 40px !important;
    }

    .primaryDisabled{
        background-color: #E9C6FC;
    }
}

@media (max-width: 450px) { 
    .stripeFormContainer{
        margin: 4% 0;

        .customLabel {
            margin-top: 15% !important;
        }

        .submitBtn{
            width: 100% !important;
        }
    }
}