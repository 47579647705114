@import "../../styles/fonts";
@import "../../styles/colors";

.upload-images {
  
  .full{
    flex-basis: 100% !important;
  }
  
  p {
    margin: 0;
    margin-bottom: 12px;
    font-size: 13px;

    .title {
      font-size: 16px !important;
      font-weight: 700;
      margin-right: 7px;
    }
  }

  .image-container {
    display: flex;
    flex-wrap: wrap;
    .image-btn,
    .preview-img {
      margin: 0 4px 10px;
      width: 25vh;
    }

    .image-btn {
      cursor: pointer;
      position: relative;
      border: 2px dashed $color-4;
      border-radius: 10px;
      max-width: 139px;
      height: 25vh;
      max-height: 139px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;

      &:hover {
        background-color: $color-5;
        opacity: 0.8;
      }
    }

    .preview-img {
      position: relative;
      border: 2px dashed $color-4;
      border-radius: 10px;
      max-width: 139px;
      height: 25vh;
      max-height: 139px;

      .btn-delete {
        position: absolute;
        background-color: $color-1;
        border-radius: 50%;
        cursor: pointer;
        top: -12px;
        right: -12px;
        width: 25px;
        height: 25px;

        svg {
          color: $color-6;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    @media (max-width: 424px) {
      .image-btn,
      .preview-img {
        margin: 0 0 10px;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .imageSizeError{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 10px;
    color: #E03D32;
    margin-bottom: 5px;
    margin-top: 15px;
  }
}
