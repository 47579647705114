@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.cashOutCard{
    background: #F2F0F2;
    padding: 20px 5px !important;
    height: 420px;
    width: 100% !important;
    overflow: hidden;
    .title{
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #463649;
        margin-top: 0 !important;
    }
    .left{
        text-align: center;
        .winnerAnnounced{
            position: relative;
            width: 280px;
            height: 192px;
            text-align: center;
            margin-bottom: 2%;
            padding: 50px 40px;
            background-image: url("../../../../../../public/assets/stars.gif");
            background-size: cover;
            overflow: hidden;
            .title{
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #CC8DFF;
            }
            .winner{
                font-family: $recoleta;
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                color: #463649;
            }
        }

        .spinWheelCard{
            position: relative;
            width: 280px;
            height: 192px;
            text-align: center;
            margin-bottom: 2%;
            padding: 30px 40px;
            overflow: hidden;
            
            .spinnerGif{
                width: 80px;
                height: 80px;
                margin-bottom: 15px
            };
            .text{
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 300;
                font-size: 16px;
                color: #463649;
            }
          }
        .btn{
            width: 280px;
            margin-top: 3%;
        }
        .primaryDisabled{
            background-color: #E9C6FC;
        }
        .defaultDisabled{
            color: #E9C6FC;
            background-color: #F2F0F2;
        }
    }
    .right{
        .row{
            margin-top: 22px;

            .head{
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #CC8DFF;
                margin: 0 !important;
                padding: 0 !important;
            }
            .paragraph{
                font-family: $recoleta;
                font-weight: 700;
                font-size: 30px;
                color: #463649;
                margin: 0 !important;
                padding: 0 !important;
            }
            .text{
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #463649;
                margin: 0 !important;
                padding: 0 !important;
            }

            .chart{
                width: 145px;
                height: 145px;
            }
        }
    }
    
}
.body-modal {
    p {
      margin: 0;
    }

    .title {
      font-family: $recoleta;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      text-align: center;
      margin-bottom: 20px;
      line-height: 54.5px;
      max-width: 453px;
    }

    .description {
      font-family: 'Poppins', sans-serif;
      max-width: 374px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 19px;
      text-align: center;
      color: #463649;
    }

    button {
      display: flex;
      max-width: 307px;
      margin: 0 auto 13px;
    }
  }

@media (max-width: 820px) {
    .cashOutCard{
        .left{
            padding-left: 3%;
        }
        .right{
            padding-left: 5%;
        }
    }
}

@media (max-width: 450px) { 
    .cashOutCard{
        height: auto !important;
        border-radius: 34px;
        margin: 35px auto 0;
    }

    .right{
        margin-top: 5%;
        padding-left: 10%;
    }

    .left{
        .winnerAnnounced{
            border-radius: 34px;
            margin: 35px auto 0;
            height: 192px !important;
        }
        .spinWheelCard{
            padding-top: 12% !important;
            overflow: hidden;
            border-radius: 34px;
            margin: 35px auto 0;
            height: 192px !important;
        }
    }
}
