.radio-field {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  user-select: none;
  font-size: 13px;

  &:hover input ~ .checkmark {
    background-color: #CC8DFF;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14px;
    width: 14px;
    background-color: #463649;
    border-radius: 50%;
    transition: all 0.3s;

    &:after {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark:after {
      content: '';
      display: block;
    }
  }
}