.AddUpdate {
  max-width: 628px;

  .text-area-field {
    margin-bottom: 31px;
  }

  .upload-images {
    margin-bottom: 29px;
  }
}

.updateCard{
  height: 90vh;
}
@media (max-width: 450px) { 
  .AddUpdate {
    margin-bottom: 13%;
    .text-area-field {
      margin-bottom: 40px;
      margin-top: 40px;
    }
  }
}