@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.donationBtnContainer{

    .formItemDonationBtn{
        .ant-form-item-required{
            font-style: normal;
            font-weight: 400;
            font-family: 'Poppins' sans-serif !important;
            color: #463649 !important;
        }

        .ant-input-number{
            width: 169px;
            height: 50px;
            box-shadow: none;
            border: 1px solid #CC8DFF;
            font-family: 'Poppins';
            font-weight: 400 !important;
            font-size: 13px;
            border-radius: 10px;
            padding-top: 10px;
            padding-left: 5px;
            margin-bottom: 3%;

            &:focus {
                box-shadow: none !important;
                border-color: #CC8DFF;
                outline-color: #CC8DFF;
            }
        
            &:hover {
                border-color: #CC8DFF;
                outline-color: #CC8DFF;
            }
        
            &:active {
                border-color: #CC8DFF;
                outline-color: #CC8DFF;
            }

            .ant-input-number-handler-wrap{
                border-radius: 0 10px 10px 0
            }
        }

        .ant-form-item-explain-error{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        }
    }

    .btnsOfMoney{
        position: absolute;
        top: 30px;
        left: 230px;
        width: 48%;
        .donationBtn{
            width: 81px !important;
            height: 50px !important;
            border-radius: 10px !important;
            font-weight: 700 !important;
            font-weight: 700 !important;
            font-size: 20px !important;
            align-items: center !important;
            font-family: 'Recoleta Alt' !important;
            margin-right: 1% !important;
            margin-bottom: 3% !important;
            box-shadow: none !important;
            margin-top: 0 !important;
        }
        .buttonSelected{
            background: #CC8DFF !important;
            color: #FFFFFF !important;
            border: 1px solid #CC8DFF !important;
    
            &:hover {
                color: #FFFFFF !important;
                outline-color: none !important;
                border-color: #CC8DFF !important;
            }
            &:visited {
                color: #FFFFFF !important;
                outline-color: none !important;
                border-color: #CC8DFF !important;
            }
            &:focus {
                color: #FFFFFF !important;
                outline-color: none !important;
                border-color: #CC8DFF !important;
            }
        }
    
        .buttonNotSelected{
            background: #FFFFFF !important;
            color: #463649 !important;
            border: 1px solid #463649 !important;
    
            &:hover {
                color: #463649 !important;
                outline-color: none !important;
                border-color: #463649 !important;
            }
            &:visited {
                color: #463649 !important;
                outline-color: none !important;
                border-color: #463649 !important;
            }
            &:focus {
                color: #463649 !important;
                outline-color: none !important;
                border-color: #463649 !important;
            }
        }
    }

    .btnsOfMoneyForUpdate{
        position: absolute;
        top: 30px;
        left: 180px;
        width: 60%;
        .donationBtn{
            width: 81px !important;
            height: 50px !important;
            border-radius: 10px !important;
            font-weight: 700 !important;
            font-weight: 700 !important;
            font-size: 20px !important;
            align-items: center !important;
            font-family: 'Recoleta Alt' !important;
            margin-right: 1% !important;
            margin-bottom: 3% !important;
            box-shadow: none !important;
            margin-top: 0 !important;
        }
        .buttonSelected{
            background: #CC8DFF !important;
            color: #FFFFFF !important;
            border: 1px solid #CC8DFF !important;
    
            &:hover {
                color: #FFFFFF !important;
                outline-color: none !important;
                border-color: #CC8DFF !important;
            }
            &:visited {
                color: #FFFFFF !important;
                outline-color: none !important;
                border-color: #CC8DFF !important;
            }
            &:focus {
                color: #FFFFFF !important;
                outline-color: none !important;
                border-color: #CC8DFF !important;
            }
        }
    
        .buttonNotSelected{
            background: #FFFFFF !important;
            color: #463649 !important;
            border: 1px solid #463649 !important;
    
            &:hover {
                color: #463649 !important;
                outline-color: none !important;
                border-color: #463649 !important;
            }
            &:visited {
                color: #463649 !important;
                outline-color: none !important;
                border-color: #463649 !important;
            }
            &:focus {
                color: #463649 !important;
                outline-color: none !important;
                border-color: #463649 !important;
            }
        }
    }

    
    @media (max-width: 450px) {
        .formItemDonationBtn{
            margin-bottom: 30px !important;
            .ant-form-item-explain-error{
                margin-top: 26%;
            }
        }
        .btnsOfMoney {
          width: 100%;
          top: 95px;
            left: 50px;
        }

        .btnsOfMoneyForUpdate {
            width: 100%;
            top: 95px;
              left: 0px;
          }
      }

}