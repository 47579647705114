@import "../../styles/fonts";
@import "../../styles/colors";

.tooltip-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 5px;

  img:nth-child(2) {
    margin-left: -8px;
    opacity: 0;
    transition: all 0.1s;
  }

  .tooltip {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    background-color: $color-1;
    position: absolute;
    border: 1px solid $color-2;
    padding: 11px;
    font-size: 12px;
    line-height: 15px;
    width: 180px;
    border-radius: 5px;
    left: 28px;
    bottom: 20%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
  }

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }

    img:nth-child(2) {
      margin-left: -2.5px;
      opacity: 1;
    }
  }
}
