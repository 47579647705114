@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.votingDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 100%;

  .information-sidebar {
    position: relative;
    overflow: hidden;
    background: #f1eff1;
    border-radius: 30px;
    padding: 26px 0;
    margin-bottom: 24px;

    p {
      margin: 0;
    }

    .decoration {
      width: 113.85px;
      height: 143.14px;
      position: absolute;
      transform: matrix(0.93, -0.4, 0.36, 0.92, 0, 0);
      bottom: -40px;
      right: -30px;
      z-index: 0;
    }

    & > .title {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      font-family: $recoleta;
      margin-bottom: 20px;
      padding: 0 43px;
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: center;
      position: relative;
      display: flex;
      margin-bottom: 20px;
      padding: 0 43px;

      .vote-button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $color-1;
        box-shadow: 0px 0px 24.3243px #ffffff,
          8.10811px 8.10811px 24.3243px rgba(174, 174, 192, 0.4);
        border-radius: 16.2162px;
        padding: 15px;
        max-width: 130px;
        min-height: 110px;
        transition: all 0.3s;
        flex: 1;
      
        &:hover {
          box-shadow: 0px 0px 24.3243px #939393,
            8.10811px 8.10811px 24.3243px rgba(174, 174, 192, 0.4);
        }
      
        img {
          width: 43px;
          margin-bottom: 5px;
        }
      
        span {
          font-family: $recoleta;
          font-style: normal;
          font-weight: bold;
          font-size: 16.2162px;
        }
      }
      
      .vote-button {
        max-width: 160px;
        width: calc((100% / 2) - 5px);
        flex: none;
        margin-right: 5px;

        &:nth-child(2) {
          margin-left: 5px;
        }
      }

      .voteBtnDisabled {
        cursor: no-drop;
        &:hover {
          box-shadow: none;
        }
      }
    }

    .data {
      position: relative;
      border-bottom: 1px solid $color-9;
      margin-bottom: 10px;

      .header {
        padding: 0 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        font-family: $recoleta;
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid $color-9;
      }

      ul.list {
        padding: 0 43px 0;
        margin: 15px 0;
        max-height: 175px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 3px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-8;
          border-radius: 30px;
        }

        li {
          font-size: 16px;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            margin-left: 15px;
            
          }
          .genderImage{
            width: 15px;
            margin-bottom: 5px;
            margin-left: 8px;
          }
        }
      }
    }
    .percentaje-details {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 20px 0 43px;
      z-index: 1;
      .chart {
        width: 145px !important;
        height: 145px !important;
        margin-right: 5%;
      }

      .detailsBox {
        margin-top: 20px;
        text-align: center;
        .title {
          font-style: normal;
          font-weight: normal;
          font-size: 16px !important;
        }

        .amount {
          font-family: $recoleta;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .sharing-game {
    margin-bottom: auto;
  }
}

@media (max-width: 450px) {
  .votingDetails{
    .information-sidebar {
      .title{
        font-size: 28px;
      }
    }
  }
}