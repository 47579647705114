@import "../../../styles/fonts";
@import "../../../styles/colors";

.drawerTab{
    font-family: $recoleta;
    font-weight: 700;
    font-size: 17px;
    text-decoration: none;
    color: $color-5;
    transition: color 0.2s;
    cursor: pointer;
    margin-left: 3%;

    &:hover {
        color: $color-4;
    }
}
.drawerTab:hover{
    color: #000;
}

.drawerContainer{
    .signIn{
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: .6px;
        border: 1px solid $color-4;
        background-color: $color-4;
        border-radius: 10px;
        height: 60px;
        width: 100%;
        margin-top: 5%;
        font-family: $recoleta;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 2px;
    }
}

@media (max-width: 450px) { 
    .drawerTab{
        font-family: "DMSans Bold";
        font-size: 22px;
    }

    .drawerContainer{
        .signIn{
            font-family: "DMSans Bold";
            font-size: 20px;
        }
    }
}
