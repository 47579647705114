@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.endGameContainer{
  .heading{
    font-family: $recoleta;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: #463649;
  }

  .subHeading{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #463649;
  }

  .date{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #463649;
  }

  .deleteBtn{
    width: 124px
  }

  .shareGame{
    padding: 0 !important;
    background: #F2F0F2;
    height: 240px;
    width: 100% !important;
  }

}

@media (max-width: 450px) { 
  .endGameContainer{
    .heading{
      font-size: 34px;
      margin-bottom: 10%;
    }
    .deleteBtn{
      margin-top: 5%;
      margin-bottom: 35%;
    }
    .shareGame{
      border-radius: 34px;
       margin: 35px auto 0;
      height: auto !important;
    }
      
  }
}