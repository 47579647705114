@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.banner{
  background-color: #463649 !important;
  border-color: #463649 !important;
  // margin-bottom: 30px !important;
  width: 100% !important;
  border-radius: 15px !important;
  .ant-alert-message{
      font-family: 'Poppins' !important;
      color: #FFFFFF !important;
      font-size: 19px;
  }
}

.mb-30{
  border-radius: 8px !important;
  margin-bottom: 30px !important;
}

@media (max-width: 450px) {
  .banner{
      .ant-alert-message{
          font-size: 16px;
      }
  }
  .mb-30{
    margin-bottom: 0px !important;
  }
}

.createGameBtn{
  color: #FFF !important;
  text-transform: uppercase !important;
  letter-spacing: .6px;
  border: 1px solid $color-4 !important;
  background-color: $color-4 !important;
  border-radius: 10px !important;
  height: 60px !important;
  width: max-content;
  margin-top: 3% !important;
  font-family: $recoleta !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  letter-spacing: 2px !important;

  @media (max-width: 450px){
    font-size: 14px !important;
    height: 40px !important;
    border-radius: 8px !important;
    margin-top: 7% !important;
  }
}
.DashboardScreen {
  overflow: hidden;

  h1 {
    font-family: $recoleta;
    font-size: 50px;
    margin-bottom: 32px;
    margin-top: 0;
  }

  .frame {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1241px;
    max-width: 100%;
    min-height: 1006px;
    background: #fff;
    border-radius: 34px;
    margin: 35px auto 0;
    padding: 67px 52px 52px 70px;
    box-sizing: border-box;

    .outlet {
      flex: 1 1 53%;
      width: 53%;
      padding-right: 46px;
    }

    .SidebarDashboard {
      flex-basis: 36%;
      width: 36%;
    }
  }

  @media (max-width: 1024px) {
    .frame {
      padding: 40px 20px;
    }
  }

  @media (max-width: 768px) {
    .frame {
      .outlet,
      .SidebarDashboard {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }

  
}

#root > div.modal-small.active > div.body-modal > div > div.socialIconsBox{
  width: 100% !important;
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
}