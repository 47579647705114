.layoutContainer{
    padding: 1% 0%;
    min-height: 100vh;
    overflow-y: hidden;
}

@media (max-width: 450px) { 
    .layoutContainer{
        padding: 0;
    }
}