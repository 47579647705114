@import "../../../../styles/fonts";
@import "../../../../styles/colors";

.games {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    width: 100%;
  }
  
  

  .scrollsBtns{
    margin-top: 3%;
    .leftBtn{
      width: 44px;
      height: 44px;
      border-radius: 10px;
      margin-right: 7px;
      background-color: #F2F0F2 !important;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);

      &:hover{
        color: initial;
        border-color: none;
      }
    }
    .disable{
      color: #C4C4C4 !important;
      background-color: #F2F0F2 !important;
      border: none;
      &:hover{
        color: #C4C4C4 !important;
      }
    }
    .rightBtn{
      width: 44px;
      height: 44px;
      border-radius: 10px;
      margin-left: 7px;
      background-color: #F2F0F2 !important;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);

    }
    .active{
      color: #ffffff !important;
      background-color: #463649 !important;
      border: none;
      &:hover{
        color: #ffffff !important;
        background-color: #463649 !important;

      }
    }
    

  }

  .bottomCouter{
    display: flex !important;
    justify-content: center;
    margin-top: 15%;
    .grayCircle{
      width: 10px;
      height: 10px;
      background-color: #F2F0F2;
      color: #F2F0F2;
      border-radius: 50%;
      margin-right: 1%;
    }
    .pinkCirlce{
      width: 24px;
      height: 8px;
      background-color: #CC8DFF;
      color: #CC8DFF;
      border-radius: 4px;
      margin-top: .3%;
      margin-right: 1%;

    }
  }

  @media (max-width: 450px) { 
    .games {
      margin-top: 10%;
    }

    .scrollsBtns{
      .leftBtn{
        width: 35px;
        height: 35px;
        position: absolute;
        left: -2%;
        bottom: 120px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

      }
      .rightBtn{
        width: 35px;
        height: 35px;
        position: absolute;
        right: -2%;
        bottom: 120px !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

      }
    }
  }
  