// .gameCard {
//     width: 228px;
//     height: 284px;
//     background: #FFFFFF;
//     box-shadow: 0px 7px 58px rgba(174, 174, 192, 0.21);
//     border-radius: 20px;
//     margin-left: 13px;
//     margin-right: 13px;
//     padding: 1% 2%;
    

//     .top{
//       margin-bottom: 40px;

//       .imageCircle{
//         width: 80px;
//         height: 80px;
//         border-radius: 50%;
//         padding-top: 22px;
//       }

//       .blue{
//         background: #F3F4FF;
//       }

//       .pink{
//         background: #F8EAFF;
//       }

//       img{
//         width: 34.29px;
//         height: 28.57px;
//       }

//       .right{
//         text-align: right;
//         width: auto;

//         .daysLeft{
//           font-family: 'Poppins', sans-serif;
//           font-style: normal;
//           font-weight: 700;
//           font-size: 13px;
//           letter-spacing: 0.08em;
//           text-transform: uppercase;
//           margin-top: 10px;
//           color: #CC8DFF;
//           padding: 0;
//         }

//         .days{
//           font-family: "Recoleta", sans-serif;
//           font-style: normal;
//           font-weight: 700;
//           font-size: 32px;
//           color: #463649;
//           margin: 0 !important;
//           line-height: 30px;
//           padding: 0;

//         }
//       }
//     }
      

//       .title{
//         height: 75px;
//         overflow: hidden;
//         font-family: "Recoleta", sans-serif;
//         font-style: normal;
//         font-weight: 700;
//         font-size: 25px !important;
//         line-height: 36px;
//         margin-bottom: 5% !important;
//         text-align: left;
//         padding-left: 0% !important;
//       }
  
//   }

// @media (max-width: 450px) {
//   .gameCard {
//     width: 208px;
//     height: 247px;
//     padding: 7% 5%;
//     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);


//     .top{
//       margin-bottom: 0;
      
//       .imageCircle{
//         width: 56px;
//         height: 56px;
//         padding-top: 12px;
//       }

//       .right{
//         margin-top: 0px !important;
//           padding-top: 0px !important;
//         .daysLeft{
//           margin-top: 0px !important;
//           padding-top: 0px !important;
//         }
//       }
//     }

//     .title{
//       font-size: 24px !important;
//       margin-bottom: 9% !important;
//       margin-top: 10% !important;
//       font-size: 20spx !important;
//       height: 60px;
//       display: flex;
//       align-items: flex-end;
//     }
//   }
// }


.gameCard{
  box-shadow: 0px 7px 58px rgba(174, 174, 192, 0.21);
  border-radius: 20px;
  width: 80% ;
  .ant-card-cover{
    border-radius: 20px;
      img{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
  }

  .btn{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 353px;
    max-width: 100%;
    padding: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 17px;
    appearance: none;
    background: #fff;
    border: 1px solid #cc8dff;
    background-color: #cc8dff;
    border-radius: 10px;
    transition: all 0.3s;
    margin-top: 10%;
    color: #FFF
  }
}