@import "../../../../styles/fonts";
@import "../../../../styles/colors";

.forgot-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .frame {
    display: flex;
    width: 1086px;
    max-width: 100%;
    height: 799px;
    background: #fff;
    border-radius: 34px;
    overflow: hidden;

    .couple-img {
      height: 100%;
      max-width: 100%;
      width: 100%;
      object-fit: cover;
    }

    .information {
      width: 49.35%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .forgot-password {
      width: 50.65%;
      padding: 50px 88px 111px 88px;
      text-align: left;
      font-size: 13px;
      background: linear-gradient(0deg, #f7f7ff, #f7f7ff);
      border-radius: 0 0 34px 34px;


      h1 {
        font-family: "Recoleta", sans-serif;
        margin-top: 35px;
      }

      .subtext {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #463649;
        margin-top: 0;
        margin-bottom: 40px;
      }

      .spam-text {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 13px;
        color: #463649;
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        margin-bottom: 130px;
      }

      .back-btn {
        position: absolute;
        bottom: 0;
        margin-bottom: 60px;
        cursor: pointer;
      }

      .input-field {
        margin-bottom: 21px;
      }

      .error-input-field {
        color: #E03D32;
      }

      .error-msg {
        color: #E03D32;
        font-weight: 500;
        font-size: 12px;
        margin-top: 15px;
        margin-bottom: 12px;
      }

      .button-default {
        margin-top: 22px;
        margin-bottom: 13px;
      }

      a {
        font-weight: 500;
        color: #463649;
        text-decoration: underline;
      }

      p {
        margin-top: 54px;

        a {
          font-weight: 700;
          color: #cc8dff;
        }
      }

      @media (max-width: 620px) {
        padding: 67px 10% 20px 10%;
      }
    }

    @media (max-width: 900px) {
      .forgot-password {
        width: 100%;

        .spam-text {
          position: relative;
          margin-bottom: 50px;
        }

        .back-btn {
          position: relative;
          margin-bottom: -20px;
        }
      }
    }

    @media (max-width: 450px) {
      .forgot-password {
        width: 100%;

        .spam-text {
          position: relative;
          margin-bottom: 50px;
        }

        .back-btn {
          position: relative;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 920px) {
    height: auto;

    .frame {
      display: flex;
      flex-wrap: wrap;
      height: auto;

      .information,
      .login {
        width: 100%;
      }

      .login {
        form {
          margin: auto;
        }
      }

    }
  }
}

@media (max-width: 450px) {
  .forgot-screen {
    .frame {
      height: 100vh;
      border-radius: 0;

      .forgot-password {
        .logo {
          width: 100px;
        }

        h1 {
          margin-top: 15%;
          margin-bottom: 10%;
        }
      }
    }
  }
}

@media (max-width: 920px) {
  .bg-register {
    overflow: auto !important
      /*To overwrite styles inline*/
    ;
    height: auto;
  }
}

.create-new-password-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .frame {
      display: flex;
      width: 600px;
      max-width: 100%;
      height: 440px;
      background: #fff;
      border-radius: 34px;
      overflow: hidden;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .renew-password{
          margin-top: 20px;
          margin-bottom: 30px;
      }

      .error-msg {
        color: #FF4D4F;
        font-size: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
      }

      .go-to-login-page{
          margin-top: 30px;
          margin-bottom: 30px;
      }
  }
}