@import "../../../styles/fonts";
@import "../../../styles/colors";

.formItem{
  .ant-form-item-required{
    font-style: normal;
    font-weight: 400;
    font-family: 'Poppins' sans-serif !important;
    color: #463649 !important;
  }
  .ant-select-selector{
    height: 48px !important;
    border: 1px solid #CC8DFF !important;
    box-shadow: none !important;
    border-radius: 10px !important;
    padding: 8px 5px 0 20px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 20px !important;
    color: #463649 !important;
  }
}