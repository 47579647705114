@import "../../styles/fonts";

.steps-bar {
  display: flex;
  align-items: center;
  white-space: nowrap;

  hr {
    width: 100%;
    appearance: none;
    border: none;
    border-bottom: 1px solid #463649;
    margin: 0 30px;
    transition: all 0.3s;

    &.active {
      border-color: #CC8DFF;
    }
  }

  .step-label {
    display: inline-flex;
    align-items: center;
    appearance: none;
    border: none;
    background: transparent;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      border-radius: 30px;
      padding: 5px;
      font-family: $recoleta;
      font-size: 30px;
      font-weight: bold;
      margin-right: 14px;
      background: #463649;
      color: #fff;
      transition: all 0.3s;
    }

    label {
      cursor: pointer;
    }

    @media (max-width: 768px) {  
      label {
        font-size: 1.2rem;
      }
    }

    @media (max-width: 450px) { 
      label {
        visibility: hidden;
        display: none;
      }
    }

    &.active {
      font-weight: bold;

      span {
        background: #CC8DFF;
      }
    }
  }

  @media (max-width: 768px) {
    hr {
      margin: 0 15px;
    }

    .step-label {
      font-size: 10px;

      span {
        width: 25px;
        height: 25px;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 620px) {
    // hr {
    //   width: 0;
    //   margin: 0;
    // }

    white-space: unset;
    
    .step-label {
      text-align: left;
    }
  }
}