@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.game-host {
  background-color: #fff;
  padding: 10px;
  width: 688px;
  max-width: 100%;

  .title {
    font-size: 50px;
    font-family: $recoleta;
    line-height: 54.5px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 16px;
  }

  .slick-slider {
    .customArrowLeft,
    .customArrowRight {
      position: absolute;
      z-index: 10;
      bottom: 40px;
      cursor: pointer;
    }

    .customArrowLeft {
      right: 10%;
    }

    .customArrowRight {
      right: 5%;
    }
  }

  .main-image {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .sub-text {
    font-size: 30px;
    font-weight: 700;
    font-family: $recoleta;
    line-height: 32.7px;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 8px;
  }

  .details {
    margin-bottom: 20px;

    .city-text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 10px;
    }

    .description {
      font-size: 13px;
      line-height: 19.5px;
      margin-top: 10px;
    }
  }
  .registry {
    .sub-text {
      padding-bottom: 17px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 8px;
    }

    img {
      width: 131px;
      margin-top: 8px;
    }
  }

  @media (max-width: 520px) {
    .title {
      font-size: 30px;
      line-height: 34.5px;
    }

    .sub-text {
      font-size: 25px;
    }
  }
}
