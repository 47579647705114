@import "../../../styles/fonts";
@import "../../../styles/colors";

.formItem{
    .ant-form-item-label{
      label[for=createGame_share_your_story]{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        font-family: 'Poppins' sans-serif !important;
        color: #463649 !important;
      }
      
    }

    .textArea{
      height: 143px;
      border: 1px solid #CC8DFF;
      border-radius: 10px;
      padding: 15px 5px 0 20px;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #463649 !important;
  
      &:focus {
        box-shadow: none !important;
        border-color: #CC8DFF;
        outline-color: #CC8DFF;
      }
  
      &:hover {
        border-color: #CC8DFF;
        outline-color: #CC8DFF;
      }
  
      &:active {
        border-color: #CC8DFF;
        outline-color: #CC8DFF;
      }

      &::placeholder{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #463649;
      }
    }
  }