.myGamesContainer{
    .backBtn{
        cursor: pointer;
    }

    .heading{
        font-weight: bold;
        color: #000;
        font-size: 1.2rem;
        text-align: center;
        margin-top: 8%;
        margin-bottom: 12%;
    }

    .gamesList {
        display: flex;
        justify-content: center;
        margin-bottom: 13%;
    }
}