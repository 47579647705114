@import "../../../styles/fonts";
@import "../../../styles/colors";

.navbarContainer{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 10vh;
  padding: 0% 2.5%;
  background: #fff;
  z-index: 1000;
  border-bottom: 2px solid $color-3;

  .left{
      align-items: baseline;
        .logo {
          position: relative;
          width: 104px;
          object-fit: contain;
          object-position: center;
          z-index: 1;
        }
  }
  
  .center{
      display: flex;
      justify-content: center;
      align-items: baseline;
      .link{
          margin-left: 30px;
          margin-right: 30px;
          padding: 0 !important;
          color: var(--black);
      }
  }
  
  .right{
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .profileImage{
          cursor: pointer;
      }
  
      .tab{
        font-family: $recoleta;
        font-weight: 700;
        font-size: 17px;
        text-decoration: none;
        color: $color-5;
        transition: color 0.2s;

        &:hover {
          color: $color-4;
        }
      }
      
      .signIn{
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: .6px;
        border: 1px solid $color-4;
        background-color: $color-4;
        border-radius: 10px;
        height: 50px;
        width: 100%;
        font-family: $recoleta;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: 2px;
        padding-top: 2%;
      }

      .menuBtn{
          width: 26px
      }
  }

}

@media (max-width: 450px) { 
  .navbarContainer{
    padding: 0% 5%;

    .right{
      .menuBtn{
        margin-top: -55%;
      }
    }
  }

  
}

// .navbar {
//   position: sticky;
//   top: 0;
//   background: #fff;
//   z-index: 1000;
//   border-bottom: 2px solid $color-3;

//   .btn-menu {
//     display: none;
//   }

//   .container {
//     position: relative;
//     padding: 15px 35px 18px;
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//   }

//   .line-nav {
//     position: absolute;
//     left: 209px;
//     box-sizing: border-box;
//     max-width: 271px;
//     z-index: 0;
//   }

//   .logo {
//     position: relative;
//     width: 104px;
//     object-fit: contain;
//     object-position: center;
//     z-index: 1;
//   }

//   .navigation {
//     flex: 1;
//     display: flex;
//     align-items: center;

//     .item-list {
//       position: relative;
//       display: flex;
//       flex-wrap: wrap;
//       align-items: center;
//       justify-content: flex-end;
//       margin-left: auto;
//       z-index: 2;
//     }

//     li {
//       a {
//         font-family: $recoleta;
//         font-weight: 700;
//         font-size: 17px;
//         text-decoration: none;
//         color: $color-5;
//         transition: color 0.2s;

//         &:hover {
//           color: $color-4;
//         }
//       }
//     }

//     .menu {
//       padding: 10px 25px 10px 0;
//       display: flex;
//       width: max-content;

//       li {
//         padding: 0 20px 0 35px;
//         position: relative;

//         img {
//           position: absolute;
//           left: 0;
//           top: 50%;
//           transform: translateY(-50%);
//         }
//       }
//     }

//     .options {
//       flex: unset;
//       padding: 10px 15px 10px 0;
//       display: flex;
//       width: max-content;

//       li {
//         padding: 0 15px 0 45px;
//         position: relative;

//         img {
//           position: absolute;
//           left: 0;
//           top: 50%;
//           transform: translateY(-50%);
//         }
//       }
//     }
//   }

//   @media (max-width: 1200px) {
//     .line-nav {
//       width: 50%;
//       padding: 0 10px;
//     }

//     .btn-menu {
//       display: block;
//       flex: 1;
//       text-align: right;

//       img {
//         width: 30px;
//         opacity: 0.7;
//       }
//     }

//     .background {
//       position: fixed;
//       background-color: $color-2;
//       width: 100%;
//       height: 100%;
//       left: 0;
//       top: 0;
//       visibility: hidden;
//       opacity: 0;
//       transition: visibility 0.5s;
//     }

//     .navigation {
//       z-index: 999;
//       position: fixed;
//       top: 0;
//       max-width: 320px;
//       background-color: $color-1;
//       flex-wrap: wrap;
//       align-items: flex-start;
//       right: -100%;
//       height: 100%;
//       display: block;
//       box-shadow: 2px 0 6px;
//       transition: right 0.5s;

//       .button-link {
//         margin: auto;
//         display: block;
//         max-width: max-content;
//         margin-top: 30px;
//       }

//       .item-list {
//         width: 100%;
//         .menu,
//         .options {
//           width: 100%;
//           flex-wrap: wrap;
//           padding: 20px;
//           li {
//             width: 100%;
//             padding: 15px 10px 15px 40px;
//           }
//         }

//         .menu {
//           padding-bottom: 0;
//         }
//       }
//     }
//   }

//   &.active {
//     .background {
//       opacity: 0.3;
//       visibility: visible;
//     }

//     .navigation {
//       right: 0;
//     }
//   }
// }


// @media (max-width: 450px) { 
//   .navbar{
//     .container{
//       padding-left: 5%;
//       padding-right: 5%;
//     }
//     .logo{
//       width: 100px;
//     }

//     .btn-menu{
//       img{
//         width: 26px;
//       }
//     }
//   }
// }