.Success {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff url('../../../../../../public/assets/bg-succcess.svg') no-repeat center center;
    box-shadow: 0px 25px 50px 20px rgba(0, 0, 0, 0.01);
    border-radius: 34px;
    min-height: 824px;
    max-width: 1241px;
    margin: 35px auto;
    padding: 51px 20px;
  }

  img {
    margin-bottom: 15px;
  }

  h1 {
    font-size: 35px;
    margin: 0;
    text-align: center;
  }

  h2 {
    font-size: 50px;
    line-height: 54.5px;
    margin: 0;
    text-align: center;
  }

  p {
    font-size: 25px;
  }

  .button-default {
    max-width: 235px;
  }

  .prefrenceBtn{
    width: 390px !important;
    max-width: 100% !important;
  }
  .noThanksBtn{
    background-color: #e6e9ec;
    border: 1px solid #e6e9ec;
    color: #000000;
    width: 390px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    max-width: 100%;
    padding: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 14px;
    appearance: none;
    border-radius: 10px;

    &:hover{
      background-color: #e6e9ec;
      border: 1px solid #e6e9ec;
      color: #000000;
    }
  }

  .backToGame{
    width: max-content !important;
    max-width: max-content !important;
    border-radius: 30px;
    height: 38px;
  }

  form {
    width: 100%;
    max-width: 390px;

    .input-field {
      margin-bottom: 22px;
    }

    .button-default {
      width: 100%;
      max-width: 100%;
      margin: -5px 0;
    }
  }
}

@media (max-width: 450px) { 
  .Success {
    img{
      width: 100px;
      height: 100px;
    }

    h1{
      font-size: 30px;
    }
    h3{
      font-size: 20px;
      margin-top: 15%;
      width: 100%;
      text-align: left;
    }
    p {
      font-size: 18px;
    }
    .backToGame{
      font-size: 10px;
      height: 25px;
      padding: 8px 6px;
    }
    .prefrenceBtn{
      margin-top: 0% !important;
    }
  }
}