.create-game-screen {
  padding: 40px 94px;

  .steps-bar {
    width: 1086px;
    max-width: 100%;
  }

  .frame {
    width: 1086px;
    max-width: 100%;
    background: #fff;
    border-radius: 34px;
    overflow: hidden;
    margin-top: 27px;
    padding: 67px 52px 20px 52px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;

    h2 {
      flex-basis: 100%;
      margin-top: 0;
    }
    .error-msg{
      color: #E03D32;
      font-weight: 500;
      font-size: 10px;
    }

    & > * {
      flex-basis: calc(50% - 50px);
      margin-bottom: 35px;
    }

    button {
      margin-left: auto;
      margin-top: 5%;

    }

    .infoStepBtn{
      width: 100%;
    }
    .gameInfoNextStepBtn{
      float: right;
      background-color: #5263FF;
      border: 1px solid #5263FF;
    }
    .primaryDisabled{
      background-color: rgba(82, 99, 255, .5) !important;
    }
    .btns {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > button {
        margin: 0;
      margin-top: 5% !important;

      }

      .primaryDisabled{
        background-color: #E9C6FC;
      }
    }

    .full {
      flex-basis: 100%;
    }

    .step-information {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      
      label {
        display: block;
        font-weight: bold;
        margin-top: 34px;
      }

      .container-text {
        padding: 18px 33px;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin-top: 14px;
        height: 234px;
        overflow: auto;
        font-size: 13px;
        line-height: 19.5px;
        overflow-x: hidden;
      }

      .radio-field {
        float: right;
      }

      .termsError{
        float: right !important;
        font-family: 'Poppins';
        font-weight: 400;
        margin-top: 10px;
        color: #E03D32;
        text-align: center;
      }
    }

    @media (max-width: 1024px) {
      padding: 20px 40px;

      & > * {
        flex-basis: calc(50% - 20px);
      }

      
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    .frame {
      > * {
        flex-basis: 100%;
      }
      
      .btns > button {
        margin-top: 15% !important;
      }

      .radio-field {
        float: left !important;
      }
    }

    
  }
}