@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.VotingGirl {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1241px;
  max-width: 100%;
  background: $color_1;
  border-radius: 34px;
  box-sizing: border-box;

  h1 {
    font-family: $recoleta;
    font-size: 50px;
    text-align: center;
    margin: 0 auto 36px;
    max-width: 453px;
    line-height: 54.5px;
  }

  a {
    text-decoration: underline;
    color: inherit;
    margin-right: auto;
  }

  .sub-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $recoleta;
    font-size: 30px;
    margin-bottom: 55px;

    img {
      margin-left: 10px;
    }
  }

  .VoteButtons {
    margin-bottom: 41px;
  }

  .button-default {
    margin-bottom: 9px;
    margin-top: 20px !important;
    font-size: 20px;
  }

  .anonymousVote{
    font-size: 1rem;
    margin-top: 3%;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #CC8DFF !important;
      border-color: #CC8DFF !important;
    }
  }

  .terms{
    margin-top: 3%;
  }
}

@media (max-width: 450px) { 
  .VotingGirl{
    .backBtn{
      background-color: #e5e5e5;
      padding: 5%;
      border-radius: 10px;
    }

    h1{
      font-size: 34px !important;
      letter-spacing: 1.1px;
      margin-top: 10%;
    }

    .sub-heading{
      font-size: 22px !important;
      img{
        width: 110px;
      }
    }

    .button-default {
      margin-bottom: 8%;
      margin-top: 5% !important;
      font-size: 18px;
    }
  }
}