@import "../../../styles/fonts";
@import "../../../styles/colors";

.formItem{
    .ant-form-item-label{
      label{
        font-style: normal;
        font-weight: 400;
        font-family: 'Poppins' sans-serif !important;
        color: #463649 !important;
      }
    }

    
    .input{
      height: 48px;
      border: 1px solid #CC8DFF;
      border-radius: 10px;
      padding: 0 5px 0 20px;
      font-family: 'Poppins';
      font-weight: 400 !important;
      font-size: 13px;
      line-height: 20px;
      color: #463649 !important;
      outline: none !important;
      box-shadow: none !important;
  
      &:focus {
        box-shadow: none !important;
        border-color: #CC8DFF !important;
        outline-color: #CC8DFF !important;
      }
  
      &:hover {
        box-shadow: none !important;
        border-color: #CC8DFF !important;
        outline-color: #CC8DFF !important;
      }
  
      &:active {
        box-shadow: none !important;
        border-color: #CC8DFF !important;
        outline-color: #CC8DFF !important;
      }

      &::placeholder{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #463649;
      }

      .ant-input{
        &::placeholder{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #463649;
        }
      }

      .ant-input-prefix{
        margin-right: 10px;
        margin-left: -5px;
        font-size: 1rem;
      }

      .ant-input-suffix{
        margin-right: 8px;
      }
    }

    .ant-form-item-explain-error{
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
    }
  }