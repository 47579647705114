@import "../../../../styles/fonts";
@import "../../../../styles/colors";

.landingPage{
    margin: 5% 10%;
    padding-bottom: 10%;
    @media (max-width: 1024px) {
        margin: 5% 3%;
    }

    @media (max-width: 450px) {
        margin: 5% 6%;
    }

    .banner{
        background-color: #463649;
        border-color: #463649 !important;
        margin-bottom: 10px;
        .ant-alert-message{
            font-family: 'Poppins' !important;
            color: #FFFFFF !important;
            font-size: 19px;
        }
    }

    .title{
        font-size: 1.875rem;
        overflow: hidden;
        margin-bottom: 10px;
        font-weight: 700;

        
    }

    @media (max-width: 450px) {
        .banner{
            .ant-alert-message{
                font-size: 14px;
            }
        }
        .title{
            font-size: 1.4rem !important;
            margin-bottom: 0px !important;
        }
    }

    .top{
        margin-bottom: 4%;

        @media (max-width: 450px) {
            margin-bottom: 10%;
            margin-top: 1%;
        }

        .ant-select-selector{
            height: 38px !important;
            width: 150px !important;
            border: 1px solid #dedede !important;
            box-shadow: none !important;
            padding: 2.5px 20px 0 15px !important;
            font-family: 'Poppins' !important;
            font-weight: 400 !important;
            font-size: 13px !important;
            color: #463649 !important;
            box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px 0 #0000001f, 0 5px 12px 4px #00000017 !important;
        }
    }


    .bottom{

        .campaignCard {
            @media screen and (max-width: 450px) {
                min-width: 270px;
            }
            border-radius: 10px;
            min-width: 310px;
            overflow: hidden;
            width: 100%;
            box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px 0 #0000001f, 0 5px 12px 4px #00000017;
            &:hover{
                transform: scale(1.01)
            }
            .campaignCardImg{
                overflow: hidden;
                position: relative;
                align-items: center;
                justify-content: center;
                .stageLabel {
                    position: absolute;
                }
                .imgWrapper {
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;
                    min-height: 190px;
                    max-height: 190px;
                    div {
                        width: 100%;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }
                .heartWrapper {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    z-index: 10;
                }
                .daysLeftWrapper {
                    width: 60px;
                    border-radius: 15px;
                    position: absolute;
                    right: 0px;
                    top: 20px;
                    display: flex;
                    height: 25px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    span {
                        font-size: 1.4rem;
                        font-family: $recoleta;
                    }
                }
                .campaignTag{
                    width: 90px;
                    border-radius: 10px;
                    position: absolute;
                    left: 20px;
                    bottom: 20px;
                    display: flex;
                    height: 30px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    background-color: rgba(0, 0, 0, 0.5);
                    span {
                        color: #FFFFFF;
                        font-size: 12px;
                        letter-spacing: 1px;
                        font-family: 'Poppins' !important;
                        font-weight: 500;
                    }
                }
    
                .campaignSuccessfulTag{
                    background-color: #CC8DFF !important;
                    width: 100px !important;
                    span{
                        color: #FFFFFF !important;
                    }
                }
            }
            .campaignCardBody {
                padding: 10px;
                flex-direction: column;
                .campaignHeadingWrapper{
                    overflow: hidden;
                    flex: 1;
                    text-align: center;
                    width: 75%;
                    align-self: center;
                    margin-bottom: 4%;
                    margin-top: 3%;
                    .title{
                        h5 {
                            font-size: 1.875rem;
                            margin-bottom: 10%;
                            line-height: 34px;
                            min-height: 72px;
                            max-height: 72px;
                            overflow: hidden;
                        }
                    }
                    .description{
                        div {
                            font-size: 0.813rem;
                        }
                        .campaignSummary{
                            min-height: 42px;
                            max-height: 42px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .campaignDetailWrapper{
                    margin: 5% 2% 7% 2%;
                    padding-bottom: 0% !important;
                    .left{
                        div{
                            margin-bottom: 1%;
                            font-size: 0.813rem;
                        }
                    }
                    .right{
                        div{
                            margin-bottom: 1%;
                            text-align: right;
                            bottom: 0;
                            font-size: 0.813rem;
                        }
                        .interest{
                            font-family: $recoleta;
                            font-size: 1.125rem;
                            line-height: 23px
                        }
                    }
                }
            }
        }
    }


    .loadMore{
        margin-top: 100%;
        margin-bottom: 50%;

        background-color: #463649;
        color: #FFFFFF;
        &:hover{
            border: 1px solid #463649;
            color: #463649;
        }
        &:visited {
            border: 1px solid #463649;
            color: #463649;
        }
        &:focus {
            border: 1px solid #463649;
            color: #463649;
        }
    }
    
}