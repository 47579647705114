@import "../../../../styles/fonts";
@import "../../../../styles/colors";


@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.webflowPredictorContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	flex-direction: column;

	.box{
		// background: #fff;
		width: 1086px;
		max-width: 100%;
		// border-radius: 34px;
		height: 100vh;

		// .right{
			font-size: 13px;
			background: linear-gradient(0deg, #f7f7ff, #f7f7ff);
            padding-top: 20%;
            padding: 0% 5%;
			// border-top-right-radius: 34px;
			// border-bottom-right-radius: 34px;

			display: flex;
  			justify-content: center;
			align-items: center;

			.details{
				margin-top: 30px;
				margin-bottom: 31px;
				font-family: 'Montserrat', sans-serif;
				font-weight: 500;
				text-align: left;

				.exclude{
					font-weight: 400;
					font-size: 11px;
				}

				a{
					color: #CC8DFF;
					font-weight: 700;
				}
			}
			.predictBtn{
				margin-top: 3%;
			}

			.block2{
				margin-bottom: 3%;
				display: flex;
				justify-content: start;
				align-items: center;
		
				h3{
					font-family: 'Montserrat', sans-serif !important;
				}

				.icons{
					width: 50px;
				}
		
				.gender-reveal-icon-instagram {
					content: "\e900";
					font-family: 'gender-reveal-icon' !important;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					font-size: 3.5rem !important;
					display: inline-block;
					padding: 0 !important;
				}
			}
		// }

	}
}

.predictionModal{
	text-align: center;
	.title{
		font-size: 24px !important;
		font-family: 'Montserrat', sans-serif;
		line-height: 35px;
		margin-bottom: 2%;
		margin-top: 5%;
	}
	// .subtitle{
	// 	font-size: 18px !important;
	// 	font-family: 'Montserrat', sans-serif;
	// 	line-height: 35px;
	// 	margin-bottom: 3%;
	// 	margin-top: 4%;
	// }
	.genderImage{
		width: 150px !important;
	}
	.paragraph{
		font-family: 'Montserrat', sans-serif;
		font-size: 18px !important;
	}
}

@media (max-width: 450px) { 
  .predictorContainer{
	background: #fff;

	.box{
		height: max-content;

		// .right{
		// 	padding-left:  0 !important;

			.details{
				text-align: justify;

			}
		// }
	}

	

  } 
}


