@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.PlaysAndDays {
  display: flex;
  justify-content: space-between;

  .box {
    background: #F1EFF1;
    color: #463649;
    border-radius: 30px;
    flex-basis: calc(50% - 10px);
    height: 130px;
    padding: 23px 35px;
    font-weight: bold;

    label {
      display: block;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    strong {
      font-family: $recoleta;
      font-size: 40px;
      line-height: 43.6px;
    }
  }
}