@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.editGameContainer {
  width: 1241px;
  max-width: 100%;
  border-radius: 34px;
  margin: 35px auto 0;
  padding: 10px 52px 52px 70px;
  box-sizing: border-box;

  h1{
    font-family: $recoleta;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    color: #463649;
  }

  .frame {
    width: 1086px;
    max-width: 100%;
    background: #fff;
    border-radius: 34px;
    overflow: hidden;
    margin-top: 27px;
    padding: 67px 52px 20px 52px;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;

    h2 {
      flex-basis: 100%;
      margin-top: 0;
    }
    .error-msg{
      color: #E03D32;
      font-weight: 500;
      font-size: 10px;
    }

    & > * {
      flex-basis: calc(50% - 50px);
      margin-bottom: 35px;
    }

    button {
      margin-left: auto;
    }

    .btns {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > button {
        margin: 0;
      }
    }

    .full {
      flex-basis: 100%;

      .votingLabel{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        color: #463649;

        .required{
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
        }
      }

      .votingBtn{
        width: 80px;
        height: 48px;
        font-family: $recoleta;
        font-weight: 700;
        font-size: 20px;
      }

      .votingText{
        margin-top: 15px;
        font-size: 12px;
      }
    }

    .step-information {
      flex-basis: 100%;

      label {
        display: block;
        font-weight: bold;
        margin-top: 34px;
      }

      .container-text {
        padding: 18px 33px;
        border: 1px solid #ddd;
        border-radius: 10px;
        margin-top: 14px;
        height: 234px;
        overflow: auto;
        font-size: 13px;
        line-height: 19.5px;
      }

      .radio-field {
        float: right;
      }
    }

    @media (max-width: 1024px) {
      padding: 20px 40px;

      & > * {
        flex-basis: calc(50% - 20px);
      }

      
    }
  }

  @media (max-width: 768px) {
    padding: 15px;

    .frame {
      > * {
        flex-basis: 100%;
      }
      
      .btns > button {
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 450px) { 
  .editGameContainer{
    h1{
      font-size: 28px;
      margin-bottom: 10%;
    }
  }
}