@import "./fonts.scss";
@import "./colors.scss";

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #463649;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.gray {
  background-color: $color-3;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  font-family: $recoleta;
}
