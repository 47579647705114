@import "../../../styles/fonts";
@import "../../../styles/colors";

.card {
    width: 86%;
    max-width: 100% !important;
    min-width: 86% !important;
    background: #fff;
    border-radius: 34px;
    margin: 35px auto 0;
    padding: 67px 52px 52px 70px;
    box-sizing: border-box;
    overflow-x: hidden;

    .outlet {
        flex: 1 1 53%;
        width: 53%;
        padding-right: 46px;
    }

    .SidebarDashboard {
        flex-basis: 36%;
        width: 36%;
    }
  }

@media (max-width: 1024px) {
    .card {
        padding: 40px 20px;
    }
}

@media (max-width: 820px) {
    .card {
        width: 95%
    }
}


@media (max-width: 450px) { 
    .card{
        width: 100%;
        height: 100% !important;
        margin: 0;
        border-radius: 0;
    }
}