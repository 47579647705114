@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.paymentContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1241px;
  max-width: 100%;
  border-radius: 34px;
  margin: 15px auto 0;
  box-sizing: border-box;

  button {
    appearance: none;
    border: none;
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    margin: 41px 0 23px;
  }

  h1 {
    font-size: 40px;
    max-width: 438px;
    line-height: 43.6px;
    margin: 0 0 35px;
  }

  h2 {
    font-size: 30px;
    line-height: 32.7px;
    margin: 0;
  }

  p {
    margin: 0 0 35px;
  }

  .box {
    display: flex;
    flex-direction: column;
    min-height: 725px;
    padding: 45px 55px;
    flex-basis: calc(50% - 17px);
    background: #fff;
    box-shadow: 0px 25px 50px 20px rgba(0, 0, 0, 0.01);
    border-radius: 34px;
    margin-bottom: 25px;
  }

  .Summary {
    h3 {
      display: block;
      padding: 8px 30px;
      background: #F1EFF1;
      font-weight: 700;
      font-size: 18px;
      border-radius: 10px;
      margin: 0 -18px 20px;
      width: calc(100% + 36px);
    }

    ul {
      li {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 14px;
        font-size: 14px;

        &:first-child,
        &:last-child {
          font-weight: bold;
          font-size: 18px;
        }

        &:last-child {
          font-family: $recoleta;

          span {
            font-size: 25px;
          }
        }

        span,
        strong {
          margin-left: auto;
        }

        hr {
          appearance: none;
          width: calc(100% + 110px);
          margin: 0 -55px;
          border: 0;
          border-bottom: 1px solid #DBDBDB;
        }
      }
    }
  }

  .FormPayment {
    h2 {
      margin-bottom: 35px;
    }

    .Separator {
      padding: 0 !important;
    }

    .check-paypal {
      margin-bottom: 3px;
    }

    .input-field {
      margin-bottom: 20px;
    }

    .col {
      display: flex;
      justify-content: space-between;

      > * {
        flex-basis: calc(50% - 25px);
        width: calc(50% - 25px);
      }
    }

    .cards {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-left: 6px;
      }
    }

    .button-default {
      width: 100%;
      margin: 19px 0 14px;
    }

    form a {
      text-decoration: underline;
      text-align: center;

      &.no-purchase {
        display: block;
      }
    }
  }

  @media (max-width: 968px) {
    flex-direction: column;

    .FormPayment .col {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }
}


@media (max-width: 450px) { 
  .paymentContainer{
    background-color: #FFF;
    border-radius: 0 !important;
    margin: 0;
    padding: 0 30px;

    button{
      background-color: #e5e5e5;
      width:48px !important;
      height: 59px !important;
    }

    .box {
      padding: 0;
      margin-top: 10%;
      min-height: auto !important;
    }

    h1{
      font-size: 32px !important;
      margin-bottom: 8%;
    }

    h2{
      font-size: 20px !important;
      margin-bottom: 3%;
    }

    .Summary {
      h3{
        width: 99%;
        margin-left: 0px;
        margin-right: 0px;

      }

      ul{
        li{
          hr{
            margin:  0 1px;
          }
        }
      }
    }

    .FormPayment{
      h2{
        font-size: 32px !important;
      }
    }
  }
}