@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.genderCard{
    padding: 7% 8% !important;
    text-align: center;
    background: #F2F0F2;
    height: 420px;
    width: 100% !important;

    .title{
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #463649;
      margin-bottom: 25px !important;

    }
    .imageCard{
      width: 154px;
      height: 132px;
      text-align: center;
      padding: 20px 0% !important;
      margin-bottom: 25px !important;
      overflow: hidden;
      cursor: pointer;
      .boy{
        margin-top: 5px;
        font-family: $recoleta;
        font-weight: 700;
        font-size: 20px;
        color: #5263FF;
      }
      .girl{
        margin-top: 5px;
        font-family: $recoleta;
        font-weight: 700;
        font-size: 20px;
        color: #CC8DFF
      }
    }
    .heading{
      font-family: $recoleta;
      font-weight: 700;
      font-size: 30px !important;
      color: #463649;
      margin-bottom: 25px !important;
    }
    
    .body-modal {
      p {
        margin: 0;
      }
  
      .title {
        font-family: $recoleta;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        text-align: center;
        margin-bottom: 20px;
        line-height: 54.5px;
        max-width: 453px;
      }
  
      .description {
        font-family: 'Poppins', sans-serif;
        max-width: 374px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 19px;
        text-align: center;
        color: #463649;
      }
  
      button {
        display: flex;
        max-width: 307px;
        margin: 0 auto 13px;
      }
    }

  }
  @media (max-width: 820px) { 
    .genderCard{
        overflow: hidden;
        padding-left: 2% !important;
        padding-right: 2% !important;
        .title{
          margin-bottom: 0px !important;
        }
        .imageCard{
          border-radius: 15px !important;
          img{
            width: 30px;
          }
        }
        
        
    }
}
  @media (max-width: 450px) { 
    .genderCard{
        height: auto !important;
        border-radius: 34px;

        .title{
          font-size: 28px !important;
          margin-bottom: 0px !important;
        }

        .imageCard{
          border-radius: 34px;
          margin: 35px auto 0;
          width: 114px !important;
          height: 112px !important;
        }
    }
}