.confirmation-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .frame {
    position: relative;
    width: 1086px;
    max-width: 100%;
    height: 750px;
    background: #fff;
    border-radius: 34px;
    text-align: center;

      img{
        margin-top: 5%;
      }

      h1 {
        font-family: "Recoleta", sans-serif;
        margin-top: 7%;
        margin-bottom: 20px;
        font-size: 50px;
        line-height: 54.5px;
      }

      p {
        margin: 0 0 6px;
        line-height: 20px;
        padding: 0 20%;
        line-height: 25px;
        letter-spacing: 1.3px;
        font-size: 16px;
      }

      .btns{
        margin-top: 3%;

        button{
          width: 272px;
          margin-right: 10px;
          margin-left: 10px;
        }
      }

      .footer{
        bottom: 0 !important;
        position: absolute;
        width: 100%;
      }
  }


  
}
@media (max-width: 450px) { 
  .confirmation-screen {
    height: auto;
    .frame {
      min-height: 100vh;
      height: 850px;
      padding: 15% 6%;
      border-radius: 0px;

      img{
        width: 100px;
      }

      h1{
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 13%;
      }

      p{
        padding: 0;
        line-height: 30px;
      }
      .btns{
        margin-top: 18%;

        button{
          width: 90%
        }
        .continueBtn{
          margin-bottom: 5%;
        }
      }

      .footer{
        width: 90%;
      }
    }
  }
  
}
