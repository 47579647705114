.bannerContainer{
    margin-bottom: 5% !important;

    .banner{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: red !important;
        background: #FFFFFF;
        border: 1px solid #B7BEFD;
        border-radius: 8px;
        text-align: left;
    }

    .ant-alert-icon{
        color: #B7BEFD !important;
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 820px) { 
    .bannerContainer{
        .banner{
            font-size: 13px;
        }
    }
}