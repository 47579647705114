@import "../../../styles/fonts";
@import "../../../styles/colors";

.spinner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.72);;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-top: 1%;

    .image {
        width: 150px;
        height: 150px;
    }

    .heading{
        margin-top: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #CC8DFF;
    }

    .advice{
        font-family: $recoleta;
        font-weight: 700;
        font-size: 30px;
        line-height: 155%;
        letter-spacing: 1.3px;
        color: #463649;
        text-align: center;
        width: 30%;
    }

    @media (max-width: 768px) {  
        .advice{
            width: 50%;
        }
    }
  
    @media (max-width: 450px) { 
        .advice{
            width: 90%;
        }
    }
}

.spinnerForBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;

    .image {
        width: 40px;
        height: 40px;
    }
}